export const getDayRange = (page) => {
    const date = new Date();
    const result = {
        startDay: new Date(date),
        lastDay: new Date(date)
    };

    // Find nearest Monday
    result.startDay.setDate(date.getDate() + parseInt(page - 1) * 5 + 1);

    // Find nearest Saturday
    result.lastDay.setDate(date.getDate() + parseInt(page) * 5);

    return result;
};

