import React, { useEffect, useState } from "react";
import { useRecoilValue } from "recoil";

import { plansAtom } from "./../../../atoms/plans";
import { FormField } from "../Input/Input.Styles";
import Select from "../Select/Select";
import { AddonsStyles } from "./Addons.Styles";
import { TextStyled } from "../../Text/Text.Styles";
import { Tooltip } from "react-tooltip";

export interface OptionType {
  value: any;
  label: string;
  isRecurring: boolean;
  data: {
    initial: string;
    recurring: string;
    initialMin: string;
    initialMax: string;
    recurringMin: string;
    recurringMax: string;
    selectedInitialAddons: any;
    selectedRecurringAddons: any;
  }  
}

interface AddonsProps {
  planId: number;
  monthly?: boolean;
  isIniAptCmplted?: boolean;
  data?: any;
  setData: any;
  setAddonsErrors: any;
}

const toTitleCase = (str: string) => {
  return str.replace(/\w\S*/g, function (txt) {
      return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
  });
};

const Addons = (props: AddonsProps): React.JSX.Element => {
  const plans = useRecoilValue(plansAtom);
  const [value, setValue] = useState("");
  const [options, setOptions] = useState<OptionType[]>([]);  
  const [selections, setSelections] = useState<OptionType[]>([]); 
  const [initialTotal, setInitialTotal] = useState<Number>(0);
  const [recurringTotal, setRecurringTotal] = useState<Number>(0);
  const [errors, setErrors] = useState<any>({});
  const [pr_recurringAddons, setPrRecurringAddons] = useState<any[]>([]);
  const [pr_initialAddons, setPrInitialAddons] = useState<any[]>([]);
  const [tooltipVisible, setTooltipVisible] = useState<{ [key: string]: boolean }>({});
  
  useEffect(() => {
    if (props.data && (props.data.pr_initial_addons || props.data.pr_recurring_addons)) {
      const initialAddons = props.data.pr_initial_addons
        ? props.data.pr_initial_addons.map((item: any) => item.product_id).join(",")
        : "";
      const recurringAddons = props.data.pr_recurring_addons
        ? props.data.pr_recurring_addons.map((item: any) => item.product_id).join(",")
        : "";
  
      const addons = [initialAddons, recurringAddons].filter(Boolean).join(",");
      
      setPrRecurringAddons(props.data.pr_recurring_addons || [] );
      setPrInitialAddons(props.data.pr_initial_addons || []);
      setValue(addons);
    }
  }, [props.data]);
  
  useEffect(() => {    
    const ids = value.split(',');
    const newSelections = [];
    let initial_addons: any[] = [];
    let recurring_addons: any[] = [];
    for(const thisId of ids) {
      for (const option of options) {        
        if(option.value === thisId) {
          newSelections.push(option);
          option.data.selectedInitialAddons && initial_addons.push(option.data.selectedInitialAddons);
          option.data.selectedRecurringAddons && recurring_addons.push(option.data.selectedRecurringAddons);
          break;
        }  
      }
    }
    setSelections(newSelections);
    props.setData((prevData: any) => ({
      ...prevData,
      selectedInitialAddons: initial_addons,
      selectedRecurringAddons: recurring_addons,
    }));
    
  }, [value]);

  useEffect(() => { 
    setValue("");
    setSelections([]);
  }, [props.planId]);
  
  useEffect(() => {  
    const addonOptions: OptionType[] = []; 
    
    const addons: any = plans[props.planId as keyof typeof plans] && plans[props.planId as keyof typeof plans]['addons'];
    
    for (const key in addons) {    
      
      const thisAddonId = addons[key as keyof typeof addons]['productId'].toString();
      const initialMin = addons[key as keyof typeof addons]['initialMin'];
      const recurringMin = props.monthly ? addons[key as keyof typeof addons]['monthlyMin'] 
                                          : addons[key as keyof typeof addons]['recurringMin'];
      const thisAddonName = toTitleCase(addons[key as keyof typeof addons]['name']);
      const thisAddonInitial = addons[key as keyof typeof addons]['initialMax'];
      const thisAddonRecurring = props.monthly ? addons[key as keyof typeof addons]['monthlyMax'] 
                                                : addons[key as keyof typeof addons]['recurringMax'];
      const thisIsTaxable = addons[key as keyof typeof addons]['isRechar'];
      const isRecurring = addons[key as keyof typeof addons]['isRecurring'];
      let initialAddons: any;
      let recurringAddons: any;
      isRecurring ? recurringAddons = {
        product_id: addons[key as keyof typeof addons]['productId'],
        amount: thisAddonRecurring,
        name: thisAddonName,
        quantity: 1,
        is_taxable: thisIsTaxable
      } : initialAddons = {
        product_id: addons[key as keyof typeof addons]['productId'],
        amount: thisAddonInitial,
        name: thisAddonName,
        quantity: 1,
        is_taxable: thisIsTaxable
      };
      
      addonOptions.push({
        value: thisAddonId,
        label: thisAddonName,
        isRecurring: isRecurring,
        data: {
          initial: thisAddonInitial,
          recurring: thisAddonRecurring,
          initialMin: initialMin,
          initialMax: thisAddonInitial,
          recurringMin: recurringMin,
          recurringMax: thisAddonRecurring,
          selectedInitialAddons: initialAddons,
          selectedRecurringAddons: recurringAddons,
        }        
      });      
      setOptions(addonOptions);      
    } 
  }, [plans]);

  useEffect(() => {  
    let newInitialTotal = 0;
    let newRecurringTotal = 0;
    selections.map((thisSelection) => {
      if(thisSelection.data) {
        newInitialTotal += parseFloat(thisSelection.data?.initial);
        newRecurringTotal += parseFloat(thisSelection.data?.recurring);
      }
    });

    setInitialTotal(newInitialTotal);
    setRecurringTotal(newRecurringTotal);
  }, [selections]);
  
  const [isChange, setIsChange] = useState<boolean>(true);
  const handleInputChange = (
    type: 'initial' | 'recurring',
    value: string,
    selectionIndex: number
  ) => {
    if(isChange) {
      const newValue = parseFloat(value) || 0;
      const selection = selections[selectionIndex];
    
      if (!selection) return;
      const maxValue =
        type === 'initial'
          ? parseFloat(selection.data?.initialMax)
          : parseFloat(selection.data?.recurringMax);
      const minValue =
        type === 'initial'
          ? parseFloat(selection.data?.initialMin)
          : parseFloat(selection.data?.recurringMin);

      setSelections((prevSelections) =>
        prevSelections.map((selection, index) =>
          index === selectionIndex
            ? {
                ...selection,
                data: {
                  ...selection.data,
                  [type]: newValue.toString(),
                  [`selected${type === 'initial' ? 'Initial' : 'Recurring'}Addons`]: {
                    ...selection.data[
                      `selected${type === 'initial' ? 'Initial' : 'Recurring'}Addons`
                    ],
                    amount: newValue,
                  },
                },
              }
            : selection
        )
      );
    
      props.setData((prevData: any) => {
        const updatedAddons =
          type === 'initial'
            ? prevData.selectedInitialAddons.map((addon: any) =>
                addon.product_id ===
                selections[selectionIndex]?.data.selectedInitialAddons?.product_id
                  ? { ...addon, amount: newValue }
                  : addon
              )
            : prevData.selectedRecurringAddons.map((addon: any) =>
                addon.product_id ===
                selections[selectionIndex]?.data.selectedRecurringAddons?.product_id
                  ? { ...addon, amount: newValue }
                  : addon
              );
    
        return {
          ...prevData,
          [`selected${type === 'initial' ? 'Initial' : 'Recurring'}Addons`]:
            updatedAddons,
        };
      });
          
      if (newValue < minValue || newValue > maxValue) {
        setErrors((prevErrors: any) => ({
          ...prevErrors,
          [selectionIndex]: `Please enter a value above ${minValue} and below ${maxValue}`,
          type: type,
          style: {border: "1px solid red", color: "red"}
        }));

        setTooltipVisible(prev => ({
          ...prev,
          [`${type}-${selectionIndex}`]: true
        }));
        
        props.setAddonsErrors((prev: any) => ([{
          ...prev,
          error: `Please enter a value above ${minValue} and below ${maxValue}`
        }]));
        return;
      }
    
      setTooltipVisible(prev => ({
        ...prev,
        [`${type}-${selectionIndex}`]: false
      }));

      setErrors((prevErrors: any) => {
        const newErrors = { ...prevErrors };
        delete newErrors[selectionIndex];
        return newErrors;
      });
      props.setAddonsErrors([]);
    
      
    }
    setIsChange(false);
  };

  const handleKeyDown = (e: any) => {
    if (
      !/[0-9.]/.test(e.key) &&
      !['Backspace', 'Delete', 'ArrowLeft', 'ArrowRight', 'Tab'].includes(e.key) // Allow control keys
    ) {
      e.preventDefault();
      setIsChange(false);
    } else {
      setIsChange(true);
    }

    if (e.key === '.' && e.currentTarget.value.includes('.')) {
      e.preventDefault();
      setIsChange(true);
    }
  };
  
  return (
    <AddonsStyles data-testid="addons-container">
      <FormField style={{marginBottom: '10px'}}>
        <Select
          placeholder={"Select"}
          options={options}
          onChange={(v) => setValue(v)}          
          value={value}
          isMulti
          withTags
          disabled={props.isIniAptCmplted}
        />
      </FormField>
      {selections.length
        ? selections.map((thisSelection, i) => (
          <div key={i}>
            <TextStyled color={'#4B5563'} className={'addons__item__label'}>{thisSelection.label}</TextStyled>
            <div className="addons__item__price">
              <TextStyled color={'#4B5563'} className={'addon__item__price__label'}>INITIAL RATE</TextStyled>
              <FormField className="price-input">
                <input 
                  value={
                    pr_initialAddons && 
                    pr_initialAddons.length > 0 && 
                    pr_initialAddons?.some((addons: any) => addons.product_id === Number(thisSelection.value)) ? 
                    thisSelection.data &&
                      parseFloat(pr_initialAddons[pr_initialAddons.findIndex(addon => addon.product_id === Number(thisSelection.value))]['amount']).toFixed(2) 
                      : parseFloat(thisSelection.data?.initial).toFixed(2)}
                  onChange={(e) => handleInputChange('initial', e.target.value, i)}
                  data-tooltip-id={errors[i] && errors.type === 'initial' ? `tooltip-initial-${i}` : undefined}
                  style={errors[i] && errors.type === "initial" ? errors.style: {}}
                  onKeyDown={handleKeyDown}
                  disabled={thisSelection.isRecurring || props.isIniAptCmplted}
                />
                {errors[i] && errors.type === 'initial' &&
                  <Tooltip 
                    className={'error-tooltip'}
                    id={`tooltip-initial-${i}`}
                    place="top"
                    content={errors[i] || ''}
                    key={`initial-${i}`}
                    isOpen={tooltipVisible[`initial-${i}`]}
                  />
                }
              </FormField>
            </div>
            <div className="addons__item__price">
              <TextStyled color={'#4B5563'}>{props.monthly ? 'MONTHLY' : 'RECURRING'} RATE</TextStyled>
              <FormField className="price-input">
                <input 
                  value={
                    pr_recurringAddons.some((addons: any) => addons.product_id === Number(thisSelection.value)) ?
                    thisSelection.data && 
                      parseFloat(pr_recurringAddons[pr_recurringAddons.findIndex(addon => addon.product_id === Number(thisSelection.value))]['amount']).toFixed(2) 
                    : parseFloat(thisSelection.data?.recurring).toFixed(2)
                  }
                  onChange={(e) => handleInputChange('recurring', e.target.value, i)}
                  data-tooltip-id={errors[i] && errors.type === 'recurring' ? `tooltip-recurring-${i}` : undefined}
                  style={errors[i] && errors.type === "recurring" ? errors.style: {}}
                  onKeyDown={handleKeyDown}
                  disabled={!thisSelection.isRecurring || props.isIniAptCmplted}
                />
                {errors[i] && errors.type === 'recurring' &&
                  <Tooltip
                    className={'error-tooltip'}
                    id={`tooltip-recurring-${i}`}
                    place="top"
                    content={errors[i]}
                    key={`recurring-${i}`} 
                    isOpen={tooltipVisible[`recurring-${i}`]}
                  />
                    
                }
              </FormField>
            </div>
            
          </div>
          ))
        : null}
        <div className="addons__total">
          <TextStyled 
            color={'#4B5563'} 
            fontWeight="bold"
            className={'price-total'}
          >
            INITIAL TOTAL
          </TextStyled>
          <TextStyled 
            color={'#4B5563'} 
            fontWeight="bold"
            className={'price-total'}
          >
            ${initialTotal.toFixed(2)} USD
          </TextStyled>                
        </div>
        <div className="addons__total">
          <TextStyled 
            color={'#4B5563'} 
            fontWeight="bold"
            className={'price-total'}
          >            
            {props.monthly ? 'MONTHLY' : 'RECURRING'} TOTAL
          </TextStyled>
          <TextStyled 
            color={'#4B5563'} 
            fontWeight="bold"
            className={'price-total'}
          >
            ${recurringTotal.toFixed(2)} USD
          </TextStyled>                
        </div>
        
    </AddonsStyles>
  );
};

export default Addons;
