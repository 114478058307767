import * as React from "react";
import { RadioInputStyled } from "./RadioInput.Styles";
import { useState, useEffect } from "react";

export interface OptionType {
  value: any;
  label: string;
  disabled?: boolean;
}

interface RadioProps {  
  options: (OptionType & { options?: OptionType[] })[]; 
  defaultValue?: any;   
  onChange: (e: any) => void;
  name: string;
}

const RadioInput = (props: RadioProps): React.JSX.Element => {
  const [selectedValue, setSelectedValue] = useState<any>(props.defaultValue);

  useEffect(() => {
    setSelectedValue(props.defaultValue);
  }, [props.defaultValue]);

  const handleChange = (value: any) => {
    setSelectedValue(value);
    props.onChange(value);
  };

  return (
    <RadioInputStyled data-testid="radio-input">
      <div className="radio__field">
        {props.options.map((o, i) => {
          return (
            <div key={i}>
              <input
                type="radio"
                checked={o.value === selectedValue}
                disabled={o.disabled || false}
                id={o.value}
                name={props.name}
                value={o.value}
                onChange={() => handleChange(o.value)}
              />
              <label className="radio__label">{o.label}</label>
            </div>
          );
        })}
      </div>
    </RadioInputStyled>
  );
};

export default RadioInput;
