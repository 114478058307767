import React, { useEffect, useState } from "react";
import { FormField } from "../Input/Input.Styles";
import Select from "../Select/Select";

interface NextBillingDateProps {
  options: any;
  isIniAptCmplted?: boolean;
  data?: any;
  setData: any;
}

const NextBillingDate = (props: NextBillingDateProps): React.JSX.Element => {
  const [selectedDate, setSelectedDate] = useState<string>("");

  useEffect(() => {
    if (props.data?.pr_next_billing_date) {
      const date = new Date(props.data.pr_next_billing_date);
      const dayOfMonth = date.getDate().toString();
      setSelectedDate(dayOfMonth);
    }
  }, [props.data?.pr_next_billing_date]);

  const handleDateChange = (value: string) => {
    setSelectedDate(value);
    
    if (value) {
      const selectedDay = parseInt(value, 10);
      const today = new Date();
      const nextMonth = new Date(today.getFullYear(), today.getMonth() + 1, selectedDay);
      const formattedDate = `${nextMonth.getFullYear()}-${(nextMonth.getMonth() + 1)
        .toString()
        .padStart(2, '0')}-${nextMonth.getDate()
        .toString()
        .padStart(2, '0')}`;
      
      props.setData((prevData: any) => ({
        ...prevData,
        pr_next_billing_date: formattedDate
      }));
    }
  };

  return (
    <FormField>
      <Select
        placeholder={"Select Date"}
        options={Array.from({ length: 28 }, (_, i) => ({ 
          value: (i + 1).toString(), 
          label: (i + 1).toString() 
        }))}
        onChange={handleDateChange}
        value={selectedDate}
        disabled={props.isIniAptCmplted}
      />
    </FormField>
  );
};

export default NextBillingDate;