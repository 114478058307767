import { atom } from "recoil";

export interface Lead {
  address_line_1?: string,
  address_line_2?: string,
  auditInfo?: any,  
  ach_auto_pay?: number,
  ach_account_number?: string,
  ach_billing_address?: string, 
  ach_billing_city?: string,
  ach_billing_state?: string,
  ach_billing_country?: string,  
  ach_billing_zip?: string,
  ach_first_name?: string,
  ach_last_name?: string,
  ach_routing_number?: string,  
  bank_name?: string,
  city?: string,  
  click_id?: string,
  client_id?: string,
  contact_id?: number,
  county?: string,
  credit_card?: string,  
  billing_county?: string,
  cookies?: string,
  discount_code?: string,
  discount_workday_id?: string,
  disposition_id?: number,
  dispositions?: any,
  email_address?: string,
  ip_address?: string,
  is_active?: boolean,
  is_qualified?: boolean,
  is_self_checkout?: boolean,
  is_test?: boolean,
  issue_description?: string,
  keyword?: string,
  last_contacted_at?: string,
  lead_id?: number,
  name_first?: string,
  name_last?: string,
  notes?: any,
  params?: string,
  partner?: string,
  pest_types?: string,
  phone_number?: string,
  phone_number_2?: string,
  postal_code?: string,
  latitude?: number,
  longitude?: number,
  pr_event_id?: number,
  pr_next_billing_date?: string | null;
  pr_customer_id?: number,
  pr_subscription_id?: number,
  pr_service_id?: number,
  pr_sold_by_id?: string,
  pr_source_id?: number,
  pr_service_charge?: number,
  pr_initial_charge?: number,
  pr_agreement_length?: number,
  pr_created_at?: string,
  pr_payment_profiles?: [any] | [],
  pr_is_initial_appointment_completed?: boolean,
  pr_is_monthly_billing?: boolean,
  province?: string,  
  property_type?: string,
  property_type_id?: number | null,
  quoted_initial?: string,
  quoted_per_service?: string,
  service_type?: string,
  selected_plan?: string,
  send_to_crm?: boolean,
  source?: string,
  tcpa_pewc_at?: any,
  url?: string,
  utm_medium?: string,
  system_id?: number,
  qr_code_id?: number,
  appointment?: any,
}

const leadAtom = atom({
  key: "lead",
  default: null
});

export { leadAtom };
