import React, { useState, useEffect } from "react";
import { useRecoilValue } from "recoil";
import { useFeatureFlag } from "configcat-react";

import LeadInfoForm from "../LeadMainInfo/LeadInfoForm";

import { Lead } from "./../../../atoms/lead";
import { plansAtom } from "./../../../atoms/plans";

interface PlanInfoProps {
  closer?: boolean;
  setData: React.Dispatch<React.SetStateAction<Lead>>;
  setErrors: React.Dispatch<React.SetStateAction<string[]>>;
  data: Lead;
  errors: string[];
  showSubscriptionFlags?: boolean;
  showLevels?: boolean;
  isMonthlyBilling?: boolean;
  isIniAptCmplted?: boolean;
  setAddonsErrors: React.Dispatch<React.SetStateAction<string[]>>;
}

export interface FormFieldType {
  label?: string;
  zipLabel?: string;
  component?: string;
  placeholder?: string;
  name: string;
  id?: string;
  additionalClassName?: string;
  autoComplete?: string;
  required?: boolean;
  regexp?: RegExp;
  validator?: RegExp;
  min?: Number,
  max?: Number,
  isMulti?: boolean;
  options?: { value: any; label: string }[];
  planId?: any;
  monthly?: boolean;
  mask?: string;
  blockLabel?: string;
  selectName?: string;
  inputName?: string;
  disabled?: boolean;
  selectedValues?: any[]
}

export interface SelectOption {
  value: any;
  label: string;
  data: {
    initial: string;
    recurring: string;
  }
}

const PlanInfo = (props: PlanInfoProps): React.JSX.Element => {
  // Config cat
  const { value: subscriptionFlagsValue } = useFeatureFlag("subscriptionFlags", false);
  const { value: levelsValue } = useFeatureFlag("levels", false);

  const plans = useRecoilValue(plansAtom);
  const [formFields, setFormFields] = useState<FormFieldType[]>([]);
  const [planOptions, setPlanOptions] = useState<SelectOption[]>([]);

  const getFormFields = (selectedPlan: number) => {
    let currentFormFields: FormFieldType[] = [];

    if(planOptions.length) {
      currentFormFields.push(
        {
          label: "Service type",
          component: "select",
          placeholder: "Select type",
          name: "pr_service_id",
          options: planOptions,
          additionalClassName: "_p-right",
          disabled: props.data.pr_is_initial_appointment_completed
        }
      );
    }

    

    if(selectedPlan) {
      let options = [
        { value: '12', label: "12 month" },
        { value: '18', label: "18 month" },
        { value: '24', label: "24 month" },
      ];
  
      if(plans[selectedPlan as keyof typeof plans]['name'] === "One Time") {
        options = [
          { value: '0', label: "No Contract" },
        ];
      }
  
      currentFormFields = currentFormFields.concat([
        {
          label: "Contract length",
          component: "select",
          placeholder: "Select contract length",
          name: "pr_agreement_length",
          options: options,
          additionalClassName: "_p-left",
          disabled: props.data.pr_is_initial_appointment_completed
        },      
      ]);

      currentFormFields = currentFormFields.concat(
        {
          label: "Initial pricing",
          component: "input",
          name: "pr_initial_charge",
          placeholder: "0",
          additionalClassName: "price-input _p-right",          
          regexp: /^[0-9]*([.][0-9]*)?$/,          
          min: plans[selectedPlan as keyof typeof plans]['prices'] && plans[selectedPlan as keyof typeof plans]['prices'][0]['initialMin'],
          max: plans[selectedPlan as keyof typeof plans]['prices'] && plans[selectedPlan as keyof typeof plans]['prices'][0]['initialMax'],
          disabled: props.data.pr_is_initial_appointment_completed
        },
        {
          label: props.isMonthlyBilling ? "Monthly pricing" : "Recurring pricing",
          component: "input",
          name: "pr_service_charge",
          placeholder: "0",
          additionalClassName: "price-input _p-left",
          regexp: /^[0-9]*([.][0-9]*)?$/,
          min: props.isMonthlyBilling ? plans[selectedPlan as keyof typeof plans]['prices'] && plans[selectedPlan as keyof typeof plans]['prices'][0]['monthlyMin'] : plans[selectedPlan as keyof typeof plans]['prices'] && plans[selectedPlan as keyof typeof plans]['prices'][0]['recurringMin'],
          max: props.isMonthlyBilling ? plans[selectedPlan as keyof typeof plans]['prices'] && plans[selectedPlan as keyof typeof plans]['prices'][0]['monthlyMax'] : plans[selectedPlan as keyof typeof plans]['prices'] && plans[selectedPlan as keyof typeof plans]['prices'][0]['recurringMax'],
          disabled: props.data.pr_is_initial_appointment_completed
        },
        
      );
      const billingDateOptions = Array.from({ length: 28 }, (_, index) => ({
        value: index + 1,
        label: (index + 1).toString(),
      }));
      const billingDateField = {
        label: "Billing Date",
        component: "nextBillingDate",
        placeholder: "Select billing date",
        name: "pr_next_billing_date",
        options: billingDateOptions,
        additionalClassName: "_p-right",
        disabled: props.data.pr_is_initial_appointment_completed
      };

      if(props.isMonthlyBilling){
        currentFormFields = currentFormFields.concat([billingDateField]);
      }
    }

    /* added prop for testing */
    if (subscriptionFlagsValue || props.showSubscriptionFlags) {
      currentFormFields.push(
        {
          blockLabel: "Subscription Flags",
          name: "subscription_flags",
          component: "subscription",
          additionalClassName: "_large-field",
          selectName: "subscription_flag",
          inputName: "subscription_notes",
        }
      );
    }

    /* added prop for testing */
    if (selectedPlan && (levelsValue || props.showLevels)) {
      currentFormFields.push({
        blockLabel: "Rate Plan",
        name: "rate",
        component: "planPrices",
        planId: selectedPlan,
        monthly: props.isMonthlyBilling,
        additionalClassName: "_large-field",
      });
      currentFormFields.push({
        blockLabel: "Addons",
        name: "levels",
        component: "addons",
        planId: selectedPlan,
        monthly: props.isMonthlyBilling,
        additionalClassName: "_large-field",
      });
    }

    return currentFormFields;
  };
  
  useEffect(() => {
    const planOptions: SelectOption[] = [];
    for (const key in plans) {
      planOptions.push({
        value: plans[key as keyof typeof plans]['serviceTypeId'],
        label: plans[key as keyof typeof plans]['name'],
        data: {
          initial: plans[key as keyof typeof plans]['prices'] && plans[key as keyof typeof plans]['prices'][0]['initialMax'],
          recurring: plans[key as keyof typeof plans]['prices'] && plans[key as keyof typeof plans]['prices'][0]['recurringMax'],
        }
      });
    }
    setPlanOptions(planOptions);
  }, [plans, subscriptionFlagsValue, levelsValue]);

  useEffect(() => {
      const selectedPlan = props.data.pr_service_id || 0;
      setFormFields(getFormFields(selectedPlan));    
  }, [planOptions, props.data.pr_service_id]);

  return <LeadInfoForm {...props} formFields={formFields} />;
};

export default PlanInfo;
